import React, { useState, useEffect, useCallback, useContext } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Login from '../components/Login'
import AdminOrderLink from '../components/AdminOrderLink'
import CustomerLookup from '../components/CustomerLookup'
import CustomerAccount from '../components/CustomerAccount'
import UserContext from '../components/context/UserContext'
import { Modal, Loading, Button, Input, Select, Spacer, Tabs, Toggle } from '@geist-ui/react'
import { CreditCard, Disc, PlusCircle, Users } from '@geist-ui/react-icons'

import { magic } from "../lib/magic"


const Account = () => {

  const [loading, setLoading] = useState()
  const [orders, setOrders] = useState([])
  const [customersloading, setCustomersLoading] = useState()
  const [tab, setTab] = useState('1')
  const [update, setUpdate] = useState()
  const [bagels, setBagels] = useState([])
  const [creamcheeses, setCreamcheeses] = useState([])
  const [filteredorders, setFilteredOrders] = useState([])
  const [pickupdate, setPickupDate] = useState()
  const [location, setLocation] = useState()
  const [packed, setPacked] = useState()
  const [dates, setDates] = useState([])
  const [fulfilled, setFulfilled] = useState()
  const [locations, setLocations] = useState([])
  const [search, setSearch] = useState()
  const [showtotals, setShowTotals] = useState()
  const [bagelstring, setBagelString] = useState('')
  const [creamcheesestring, setCreamcheeseString] = useState('')
  const [adding, setAdding] = useState()
  const [total, setTotal] = useState(0)
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState([])
  const [customersearch, setCustomerSearch] = useState()
  const [upcomingorders, setUpcomingOrders] = useState([])

  const { currentuser, admin } = useContext(UserContext)


  useEffect(() => {
      fetch(`/.netlify/functions/fauna-all/`, {
        body: JSON.stringify({
          index: 'bagels_all'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setBagels(response.data || []);
        })
     .catch(error => console.log(error));
     fetch(`/.netlify/functions/fauna-all/`, {
        body: JSON.stringify({
          index: 'creamcheeses_all'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setCreamcheeses(response.data || []);
        })
     .catch(error => console.log(error));
  }, [])


  useEffect(() => {
    setLoading(true);
    fetch(`/.netlify/functions/fauna-all/`, {
    body: JSON.stringify({
      index: 'orders_all'
     }),
     method: 'POST'}).then((response) => {
      return response.json()}).then((response) => {
        setOrders(response.data || []);
        setLoading();
      })
    .catch(error => console.log(error), setLoading());
  }, [update])

  useEffect(() => {
    if(tab === "3") {
    setCustomersLoading(true);
    fetch(`/.netlify/functions/fauna-all/`, {
        body: JSON.stringify({
          index: 'users_all'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setCustomers(response.data || []);
          setCustomersLoading();
        })
     .catch(error => console.log(error), setCustomersLoading());
    }
  }, [tab])

  useEffect(() => {
  if(orders.length > 0) {
    setFilteredOrders(orders.filter(function (e) {
      if(pickupdate) {
        return e[0] == pickupdate;
      }
      else {
        return e;
      }
    }).filter(function (e) {
      if(location) {
        return e[1] == location;
      }
      else {
        return e;
      }
    }).filter(function (e) {
      if(packed) {
        return e[2];
      }
      else {
        return !e[2];
      }
    }).filter(function (e) {
      if(search) {
        return e[6].toLowerCase().includes(search.toLowerCase()) || e[7].includes(search.toLowerCase());
      }
      else {
        return e
      }
    }).filter(function (e) {
      if(fulfilled === "false") {
       return e[3] === false
      }
      else if(fulfilled === "true") {
        return e[3] === true
      }
      else if(fulfilled === "missed") {
        return e[3] === "missed"
      }
      else {
        return e;
      }
    }).sort((a,b) => a[12] > b[12] ? 1 : -1));
   let thedates = [];
   let thelocations = [];
   orders.forEach((order) => {
     !thedates.includes(order[0]) && thedates.push(order[0]);
     !thelocations.includes(order[1]) && thelocations.push(order[1]);
   });
   setDates(thedates);
   setLocations(thelocations);
 }
 else {
   setFilteredOrders([]);
   setDates([]);
   setLocations([]);
 }
}, [orders, pickupdate, location, packed, fulfilled, search])

  useEffect(() => {
    if(orders.length > 0) {
      let thestring = '';
      let thecreamcheesestring = '';
      let thetotal = 0;
      orders.filter((order) => pickupdate ? order[0] === pickupdate : true).forEach((order, index) => {
        thestring = thestring.concat((index > 0 ? ',' : '') + order[4]);
        thecreamcheesestring = order[5] ? thecreamcheesestring.concat((index > 0 ? ',' : '') + order[5]) : thecreamcheesestring;
        thetotal = thetotal + (order[17] ? order[17] : 0);
      });
      setBagelString(thestring);
      setCreamcheeseString(thecreamcheesestring);
      setTotal(thetotal > 0 ? thetotal / 100 : 0);
    }
    else {
      setBagelString('');
      setCreamcheeseString('');
      setTotal(0);
    }
  }, [orders, pickupdate])


 useEffect(() => {
   if(customer.length > 0) {
     fetch(`/.netlify/functions/fauna-find/`, {
      body: JSON.stringify({
        index: 'orders_by_user',
        id: customer[5]
       }),
       method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setUpcomingOrders(response.data || []);
        })
      .catch(error => console.log(error));
   }
 }, [customer, update])


  const tabsHandler = (val) => setTab(val)
  
  return (
    <>
      <Helmet>
        <title>Admin - Saturday Bagel Club</title>
      </Helmet>
      {!admin && <Login page='account' onChange={() => navigate('/')} />}
      <Modal visible={showtotals} onClose={() => setShowTotals(false)}>
          <h6 style={{marginBottom:'10px'}}>{pickupdate ? pickupdate : 'All Dates'}</h6>
          <p className="small">{bagelstring.split(",").length > 0 ?  bagelstring.split(",").length / 6 : 0} orders - ${total}</p>
          <p className="bold">{bagelstring.split(",").length} Bagels</p>
          <div className="totals">
           {bagelstring.split(",").length > 0 && [...new Set(bagelstring.split(","))].sort((a,b) => a > b ? 1 : -1).map((bagel) => (
            <p><span>{bagel}</span>{bagelstring.split(",").filter(x => x == bagel).length}</p>
           ))}
          </div>
          <p className="bold">{creamcheesestring.split(",").length} Creamcheeses</p>
          <div className="totals">
           {creamcheesestring.split(",").length > 0 && [...new Set(creamcheesestring.split(","))].sort((a,b) => a > b ? 1 : -1).map((creamcheese) => (
            <p><span>{creamcheese}</span>{creamcheesestring.split(",").filter(x => x == creamcheese).length}</p>
           ))}
          </div>
      </Modal>
      <Modal visible={adding} wrapClassName="no-padding" onClose={() => setAdding(false)}>
        <CustomerLookup order="true" onChange={(resp) => (setAdding(false), setUpdate(resp))} />
      </Modal>
      <div className="account admin">
        {loading && <Loading />}
        {currentuser.length > 0 && admin && !loading && <>
          <div className="add top"><PlusCircle size={25} onClick={() => (setTab('1'), setAdding(true))} /></div>
          <Tabs value={tab} align="center" leftSpace={0} onChange={tabsHandler}>
           <Tabs.Item label={<><Disc /> Orders</>} value="1">
             <Button type="secondary" scale={0.5} icon={<Disc />} onClick={() => setShowTotals(true)} shadow>
               Show Totals
             </Button>
             <div className="selects">
               <Select scale={0.5} placeholder="Pickup Date" value={pickupdate} onChange={(val) => setPickupDate(val)} disableMatchWidth>
                  <Select.Option value="">All Dates</Select.Option>
                {dates.length > 0 && dates.sort((a,b) => new Date(a) > new Date(b) ? 1 : -1).map((date) => (
                  <Select.Option value={date}>{date}</Select.Option>
                 ))}
              </Select>
              <Select scale={0.5} placeholder="Location" value={location} onChange={(val) => setLocation(val)} disableMatchWidth>
                  <Select.Option value="">All Locations</Select.Option>
                {locations.length > 0 && locations.sort((a,b) => a > b ? 1 : -1).map((location) => (
                  <Select.Option value={location}>{location}</Select.Option>
                 ))}
              </Select>
              <Select scale={0.5} placeholder="Picked Up?" value={fulfilled} onChange={(val) => setFulfilled(val)} disableMatchWidth>
                  <Select.Option value="">All</Select.Option>
                  <Select.Option value="false">Not Picked Up Yet</Select.Option>
                  <Select.Option value="true">Picked Up</Select.Option>
                  <Select.Option value="missed">Missed</Select.Option>
              </Select>
             </div>
             <Toggle onChange={(e) => setPacked(e.target.checked)} value={packed} /><span className="small"> Show Packed Orders</span>
             <Spacer h={1} />
             <Input width="90%" className="search" placeholder="Search by Name or Phone #" value={search} clearable onChange={(e) => setSearch(e.target.value)} />
             <Spacer h={1} />
             <p className="small">Showing {filteredorders.length} orders</p>
             <div className="admin-orders">
              {filteredorders.length > 0 && <>
                 {filteredorders.map((order) => (
                    <AdminOrderLink order={order} onChange={(resp) => setUpdate(resp)} loading={loading} />
                  ))}
                </>}
              </div>
              {filteredorders.length < 1 && <p>There are no Orders that meet your selected criteria.</p>}
            </Tabs.Item>
            <Tabs.Item label={<><Users/> Customers</>} value="3">
               {customersloading && <Loading />}
               {!customersloading && customer.length < 1 &&
                 <div className="customer-list">
                 <Input width="90%" className="search" placeholder="Search by Name or Phone #" value={customersearch} clearable onChange={(e) => setCustomerSearch(e.target.value)} />
                 <Spacer h={1} />
                  {customers.length > 0 && customers
                    .filter((customer) => customersearch ? customer[0].toLowerCase().includes(customersearch.toLowerCase()) 
                      || customer[2].toLowerCase().includes(customersearch.toLowerCase()) : true)
                    .map((customer) => (
                    <div className="customer-link" onClick={() => setCustomer(customer)}>
                      <h4>{customer[0]}</h4>
                      <div>
                        <p>{customer[2]}</p>
                        <p>{customer[3]}</p>
                      </div>
                    </div>
                   ))}
                </div>}
                {customer.length > 0 && <div className="account" style={{paddingTop:'0'}}>
                    <h4>{customer[0]}</h4>
                    <p>{customer[3]}</p>
                    <Button scale={0.25} type="secondary" onClick={() => setCustomer([])} shadow>Back to All</Button>
                    <Spacer h={1.5} />
                    <CustomerAccount customer={customer} upcomingorders={upcomingorders} admin="true" onChange={(resp) => setUpdate(resp)} />
                </div>}
            </Tabs.Item>
          </Tabs>
         </>}
      </div>
    </>
  )
}

export default Account
