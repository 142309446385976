import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import OrderForm from './OrderForm'
import { Modal, Loading, Input, Button, Note, Spacer, Toggle } from '@geist-ui/react'
import PhoneInput from 'react-phone-number-input'

import 'react-phone-number-input/style.css'

export default function CustomerLookup (props) {

 const [loading, setLoading] = useState()
 const [customers, setCustomers] = useState([])
 const [customer, setCustomer] = useState([])
 const [search, setSearch] = useState()
 const [byname, setByName] = useState()


  function searchCustomer() {
   setLoading(true);
   fetch(`/.netlify/functions/fauna-find/`, {
      body: JSON.stringify({
        index: byname ? 'users_by_name' : 'users_by_phone',
        id: byname ? search.toUpperCase() : search
      }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setCustomers(response.data || {});
          setLoading();
        })
     .catch(error => (console.log(error), setLoading()));
  }

  return (<>
    {customer.length < 1 && <div className="padding">
      <h6 style={{marginBottom:'10px'}}>Lookup Customer</h6>
      <div className="flex">
        <Toggle onChange={(e) => (setByName(!byname), setSearch(), setCustomers([]))} value={byname} />
        <p className="small">By Name</p>
      </div>
       <Spacer h={1} />
      {byname ? 
        <Input width="100%" className="search" placeholder="Search by Name" value={search} clearable onChange={(e) => setSearch(e.target.value)} /> :
        <PhoneInput width="100%" placeholder="Search by Phone" id="phone" defaultCountry="US" value={search} 
              onChange={setSearch} contentEditable />
       }
      {byname && <Spacer h={1} />}
      <Button width="40%" type="secondary" scale={0.5} disabled={!search} loading={loading} onClick={() => searchCustomer()} shadow>Search</Button>
      <div className="customer-list">
      	{customers.length > 0 && customers.map((customer) => (
          <div className="customer-link" onClick={() => setCustomer(customer)}>
            <h4>{customer[0]}</h4>
            <p>{customer[2]}</p>
          </div>
         ))}
      </div>
    </div>}
    {customer.length > 0 && props.order && <OrderForm customer={customer} onChange={(resp) => props.onChange(resp)} />}
  </>)
}